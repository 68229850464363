export default function ({ $axios, error: nuxtError, redirect, store }) {
  $axios.onError((error) => {
    if (process.server) {
      return nuxtError({
        statusCode: error.response.status,
        message: error.message,
      });
    }
    if (error.response.status === 401) {
      store.commit("setUser", null);
    }
  });
}
