<template>
  <v-app id="inspire">
    <Header />
    <v-main>
      <v-container>
        <v-row justify="center">
          <v-col cols="12" xl="9">
            <Nuxt />
          </v-col>
        </v-row>
      </v-container>
      <Cookie />
      <v-btn
        v-show="fab"
        v-scroll="onScroll"
        fab
        dark
        fixed
        bottom
        right
        @click="toTop"
      >
        <v-icon>mdi-arrow-up</v-icon>
      </v-btn>
    </v-main>
    <Footer />
  </v-app>
</template>

<script>
export default {
  name: "DefaultLayout",
  components: {
    Header: () => import("@/components/Header"),
    Cookie: () => import("@/components/Cookie"),
    Footer: () => import("@/components/Footer"),
  },
  data () {
    return {
      fab: false,
    };
  },
  methods: {
    onScroll (e) {
      if (typeof window === "undefined") { return; }
      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.fab = top > 20;
    },
    toTop () {
      this.$vuetify.goTo(0);
    },
  },
};
</script>
