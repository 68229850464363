import Vue from "vue";
import ToastPlugin from "vue-toast-notification";
import "assets/vue-toast-notification/index.scss";

Vue.use(ToastPlugin, {
  // One of the options
  position: "bottom",
  pauseOnHover: false,
  duration: 2000,
});
