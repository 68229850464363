export default ({ store, app: { $cookies } }) => {
  store.subscribe((mutation, state) => {
    $cookies.set("flick_club_state", state, {
      path: "/",
      expires: new Date("2099-01-01T00:00:00.000Z"),
    });
  });

  const parsedState = $cookies.get("flick_club_state");
  if (parsedState) {
    store.commit("RESTORE_MUTATION", parsedState);
  }
};
