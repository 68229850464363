<template>
  <v-container>
    <BackgroundColor />
    <v-row class="justify-center text-center" align-content="center">
      <v-col
        class="mt-12 z-index-100"
        cols="4"
        sm="12"
      >
        <h1 v-if="error.statusCode === 404">
          {{ pageNotFound }}
        </h1>
        <h1 v-else>
          {{ otherError }}
        </h1>
        <h4>
          Visit the <a href="/">home page</a>
          or contact us via
          <NuxtLink to="/feedback">
            feedback form
          </NuxtLink>
        </h4>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "EmptyLayout",
  components: {
    BackgroundColor: () => import("@/components/BackgroundColor.vue"),
  },
  layout: "empty",
  props: {
    error: {
      type: Object,
      default: null,
    },
  },
  data () {
    return {
      pageNotFound: "404 Not Found",
      otherError: "An error occurred",
    };
  },
  head () {
    const title = this.error.statusCode === 404 ? this.pageNotFound : this.otherError;
    return {
      title,
    };
  },
};
</script>
