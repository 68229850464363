export const state = () => ({
  user: null,
});

export const mutations = {
  setUser (state, user) {
    state.user = user;
  },
  RESTORE_MUTATION (state, restoredState) {
    this.replaceState(Object.assign(state, restoredState));
  },
};
